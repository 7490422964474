<template lang="pug">
.content-step2
  loading-logo(v-if="loading")
  template(v-else)
    om-select#listName(
      v-model="listId"
      :options="lists"
      :label="$t('integrationFlow.integrationModal.general.listHelper', { type: 'Selzy' })"
      :placeholder="$t('integrationFlow.integrationModal.selectAList')"
    )
    om-select#optInType.mt-3(
      v-model="optInType"
      :options="optInTypes"
      :label="$t('integrationFlow.integrationModal.general.optInTypeHelper')"
      :helperMessage="optInType ? $t(`integrationFlow.selzy.optInTypeHelpers.${optInType.key}`) : ''"
    )
</template>
<script>
  import { IntegrationService } from '@/services/integrations/integrationService';

  const DEFAULT_OPTIN_TYPE = { key: 3 };

  export default {
    props: {
      settings: {
        type: Object,
        required: true,
      },

      validations: {
        type: Object,
        required: true,
      },
    },

    data() {
      return {
        loading: false,
        listId: null,
        lists: [],
        hasError: false,
        optInType: DEFAULT_OPTIN_TYPE,
        optInTypes: [
          { key: 3, value: this.$t('integrationFlow.selzy.optInTypes.never') },
          { key: 0, value: this.$t('integrationFlow.selzy.optInTypes.always') },
          { key: 4, value: this.$t('integrationFlow.selzy.optInTypes.onlyForNew') },
        ],
      };
    },

    watch: {
      listId(option) {
        this.updateListSetting(option.key);
      },
      optInType(option) {
        this.updateOptInTypeSetting(option.key);
      },
    },

    async mounted() {
      // IMPORTANT to load data then set model (behavior of om-select)
      const { lists, fields, tags } = await this.loadData();

      this.updateSettings({ fields, tags });

      this.lists = lists;

      const { listId, optInType } = this.settings.convertedSettings;

      // om-select selected option only needs "key" from object
      this.listId = listId ? { key: listId } : null;
      this.optInType = this.isNumber(optInType) ? { key: optInType } : DEFAULT_OPTIN_TYPE;
    },

    methods: {
      isNumber(value) {
        return typeof value === 'number' && !Number.isNaN(value);
      },
      async loadData() {
        this.loading = true;
        const integrationService = new IntegrationService(this.$apollo);
        const result = {
          lists: [],
          fields: [],
        };

        try {
          const { success, lists, fields, tags } = await integrationService.fetchIntegrationData(
            this.settings.type,
            this.settings.id,
          );

          if (success) {
            result.lists = lists.map((list) => ({
              key: list.id,
              value: list.title,
            }));
            result.fields = fields.map((field) => ({
              key: field.id,
              value: field.public_name || field.name,
            }));
            result.tags = tags?.length ? tags.map((tag) => tag.name) : [];
          } else {
            this.errorNotification(result.error);
          }
          this.loading = false;
        } catch (e) {
          this.errorNotification(e.message);
          this.loading = false;
        }

        return result;
      },
      errorNotification(message) {
        this.hasError = true;
        this.$notify({
          type: 'error',
          text: message,
        });
        setTimeout(() => {
          this.$bus.$emit('integration-show-first-step');
        }, 2000);
      },

      updateListSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.listId = value;
        this.$emit('update:settings', settings);
      },

      updateOptInTypeSetting(value) {
        const settings = { ...this.settings };
        settings.convertedSettings.optInType = value;
        this.$emit('update:settings', settings);
      },

      updateSettings(settings) {
        const newSettings = { ...this.settings, ...settings };
        this.$emit('update:settings', newSettings);
      },

      updateTagsSetting(value) {
        const settings = { ...this.settings };
        settings.tags = value;
        this.$emit('update:settings', settings);
      },
    },
  };
</script>
